import './Mixture.sass';
import { openModalComponent } from '@/shared/components';
import MixtureEditorDialog, { Props } from './MixtureEditorDialog';

export default function launchMixtureEditorDialog(props: Props = {
  mixfileString: (document.getElementById('mixture_data') as HTMLTextAreaElement)?.value || '',
  callbackSave: (mixfileString) => (document.getElementById('mixture_data') as HTMLTextAreaElement).value = mixfileString,
}) {
  return openModalComponent(MixtureEditorDialog, props, true);
}
